import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";

import Layout from "../components/layout";
import Seo from "../components/seo";

const landing = () => {
  return (
    <Layout pageInfo={{ pageName: "Terms" }}>
      <ScrollUpButton ContainerClassName="custom-scroll-button" />
      <Seo title="DeFi Powered Rewards Generator" />
      <div className="py-140 mb-4  text-justified ">
        <Container>
          <Row className="mb-2 ">
            <Col md={12} className="mb-md-5 mb-4">
              <h1 align="center">Privacy Policy</h1>
              <h2>Introduction</h2>
              <p>
                This Privacy Policy (“Policy”) applies to all the products, services and websites offered by HedgePay
                Sdn Bhd (“HedgePay”), except where otherwise noted. We refer to those products, services, and websites
                collectively as the “services” in this Policy.
              </p>
              <p>
                References in this Policy, to data, personal information, or information, are all references to Personal
                Data. Personal Data, as defined by General Data Protection Regulation (“GDPR”), includes information
                relating to an individual who is identified or identifiable from such information, either alone or in
                conjunction with other information (“Data Subject”).
              </p>
              <h2>Why do we process your personal data?</h2>
              <p>
                In compliance with GDPR, we inform you that we will process the types of Personal Data more fully
                prescribed in this Policy:
              </p>
              <ul>
                <li>to manage the contracting of services made through our website including billing and delivery.</li>
                <li>to send communications and commercial and/or promotional information.</li>
                <li>
                  to comply with our legal obligations and transfer data to state authorities that are so required in
                  accordance with legal and regulatory provisions.
                </li>
              </ul>
              <h2>What data do we collect?</h2>
              <p>The information we collect when you use Hedgepay falls into the following categories.</p>
              <h2>Contact Information</h2>
              <p>
                You might provide us with your contact information, whether through use of our services, an upload of
                content by you to our website or an interaction with customer support.
              </p>
              <h2>Payment Information</h2>
              <p>
                We require you to provide us with your billing information such as your cryptocurrency wallet address or
                your financial information when you are purchasing our services using fiat currency.
              </p>
              <p>Usage Information</p>
              <p>
                We collect usage information about your activity on our website, including the web pages you visit,
                services you obtain, content you consume, interactions you make, preferences you set and communication
                you send and receive.
              </p>
              <h2>Device Information</h2>
              <p>
                When visiting our website, we collect information such as your computer or mobile device operating
                system name and version, manufacturer and model, browser type, browser language and screen resolution.
              </p>
              <h2>Information from Social Networking Websites</h2>
              <p>
                Our website includes interfaces that allow you to connect with social networking sites (“SNS”). If you
                connect to a SNS through our website, you authorize us to access, use and store the information that you
                agreed the SNS could provide to us based on your settings on that SNS.
              </p>
              <h2>Automated Decision Making and Profiling</h2>
              <p>
                We do not collect your personal data for the purposes of automated decision-making or profiling.
                However, we may share your data to fulfill obligations imposed by law, in which case we will inform you
                of any such processing and provide you with an opportunity to object.
              </p>
              <h2>Cookies</h2>
              <p>
                Cookies are small data files stored on the hard drive of your device by a website, which may contain an
                anonymous unique identifier. You can choose to reject or limit the use of cookies; however, it will
                restrict your access to certain portions of our website and may hinder your user experience.
              </p>
              <p>
                We use first party cookies served directly by us and third-party cookies served by service providers to
                optimize your user experience when you are using our services.
              </p>
              <h2>What do we use cookies for?</h2>
              <p>The following is an explanation of the types of cookies we use and the purposes they are used for.</p>
              <h2>Essential Cookies</h2>
              <p>
                These cookies are essential to provide you with services available through our website and to enable you
                to use some of its features. The unavailability of these cookies will disrupt the transmission of
                communication over a network and therefore will disrupt providing the services you have requested for.
              </p>
              <h2>Functionality Cookies</h2>
              <p>
                These cookies allow our website to remember choices you make when you use our website, such as your
                language preferences, your login details, or the changes you make to the parts of our website which you
                can customize. They help us to provide you with a personal and convenient experience based on your
                preferences.
              </p>
              <h2>Analytical and Performance Cookies</h2>
              <p>
                These cookies are used to collect information about traffic and the usage of our website. They help us
                understand the most and least popular pages of our websites based on the engagement of the visitors.
                This information collected is an aggregation of the engagement of visitors as a whole and does not
                indicate to a specific individual. We use this information to gather broad demographic information and
                improve the performance of our website.
              </p>
              <p>
                We use Google Analytics for this purpose, and you can see how Google Analytics works by clicking here.
              </p>
              <h2>Social Media Cookies</h2>
              <p>
                These cookies are used when you share information of your social media accounts to our website when
                submitting content via a specified form available on our website.
              </p>
              <h2>Disabling cookies</h2>
              <p>
                You can typically remove or disable cookies via your browser settings, and depending on your geographic
                location you can withdraw consent to non-essential cookies using changing the cookie preferences.
              </p>
              <h2>Advertising</h2>
              <p>
                We may serve advertisements in the form of ad banners on our website when you visit our website and/or
                use the services. We do not allow any third party to display their advertisements based on your activity
                on our website or other webpages.
              </p>
              <h2>Who do we share your data with?</h2>
              <p>
                We may share your data with third parties if we believe that sharing your personal data is for one of
                the purposes specified in this policy.
              </p>
              <p>
                We have executed the corresponding contracts for the engagement of data processing with each of the
                third-party partners and service providers to ensure that these third parties will take necessary steps
                to protect your personal data in accordance with the legislative framework. Your personal data may also
                be shared with relevant state authorities in instances where the necessity arises out of legal
                obligation.
              </p>
              <p>
                Except for the foregoing, we will not share your personal data with any other parties without obtaining
                your express consent.
              </p>
              <h2>International Data Transfer</h2>
              <p>
                As an organization conducting a global operation, we have stakeholders from around the world taking part
                in our business operations including you and our third-party partners and service providers. To
                facilitate our operation in different jurisdictions of law we may transfer your personal information
                internationally.
              </p>
              <p>
                Therefore, your personal data may be processed outside of your country or jurisdiction, where data
                protection and privacy regulations may not offer the same level of protection as in other parts of the
                world. Necessary measures are taken to establish suitable technical, organizational, and contractual
                obligations and ensure that any such transfer involving your personal data is carried out in compliance
                with the legislative frameworks.
              </p>
              <h2>How long do we retain your data?</h2>
              <p>
                We will retain your personal data for a period consistent with the purposes of collection as specified
                in this policy, if the user does not revoke the consent granted. However, we may retain your personal
                data for a longer retention period if required by state authorities as necessitated by legal
                obligations.
              </p>
              <h2>Security</h2>
              <p>
                We take reasonable physical, technical, and administrative measures to safeguard the personal data that
                we process or use. While we adhere to the generally accepted standards to ensure your personal data is
                protected, no method of transmission or storage system can be guaranteed to be completely secure.
              </p>
              <p>
                If you have reason to believe that your interaction with us is no longer secure, you can report to us by
                clicking here.
              </p>
              <h2>Your Rights</h2>
              <p>
                You are entitled to certain rights relating to your personal data, subject to data protection laws in
                your local jurisdiction. These rights may include:
              </p>
              <ul>
                <li>Accessing your personal data held by us.</li>
                <li>Rectifying inaccurate personal data and ensuring such rectification is complete.</li>
                <li>Erasing your personal data.</li>
                <li>Restricting our processing of your personal data.</li>
                <li>Transferring your personal data to another data controller.</li>
                <li>Objecting to any processing of your personal data.</li>
                <li>Opting out of certain disclosures of your personal data to third parties.</li>
                <li>
                  Withdrawing your consent to processing of data, provided such withdrawal of consent does not amount to
                  unlawfulness.
                </li>
              </ul>
              <p>
                You can exercise your rights relating to personal data and obtain more information about how we process
                your personal data by contacting us here.
              </p>
              <h2>Our Policy on Children</h2>
              <p>
                Our website does not encourage children under 16, or other such applicable age of consent for privacy
                purposes in relevant jurisdictions, to use or purchase services on our website. In the event a parent or
                guardian becomes aware of such an instance they are encouraged to contact us to take necessary steps to
                exercise rights relating to personal data specified in this policy.
              </p>
              <h2>Changes to the Privacy Policy</h2>
              <p>
                We will modify this policy when deemed necessary to reflect modifications to our practices,
                technologies, legal requirements, and other relevant factors. Such successive modifications will be
                published on our website and will be communicated to you seeking your consent to those changes.
              </p>
              <h2>Contact Us</h2>
              <p>
                If you have any concerns about this privacy policy or any other relevant inquiries, you can reach us
                here.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default landing;
